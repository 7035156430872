import React from "react";
import { Helmet } from "react-helmet";

import { Layout } from "@components";
import {
  SectionOfMainCt,
  SectionTwoCt,
  SectionThreeCt,
  SectionFourCt,
  SectionFiveCt,
} from "@templates/acquiring";

const AcquiringPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Сервис приема онлайн платежей на сайте | Онлайн касса прием платежей на сайте</title>
      <meta name="description" content="Ищите сервис приема онлайн платежей? Наш сайт поможет вам с этим. Подключение платежного сервиса. Официальные выплаты самозанятым" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/acquiring-for-site" />
    </Helmet>
    <Layout>
      <SectionOfMainCt h1Text="Прием платежей на сайте онлайн"/>
      <SectionTwoCt />
      <SectionThreeCt />
      <SectionFourCt />
      <SectionFiveCt />
    </Layout>
  </>
);

export default AcquiringPage;
